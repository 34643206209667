@property --bg-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}

@keyframes spin {
  to {
    --bg-angle: 360deg;
  }
}

div.animated-border {
  animation: spin 2.5s infinite linear running;

  background:
          padding-box,
          conic-gradient(
                    from var(--bg-angle) at -25% -25% in oklch longer hue,
                          oklch(0.85 0.37 0) 0 0
          )
          border-box;
}
